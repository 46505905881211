<template>
  <div class="item">
    <div class="top">
      <div class="l">
        跟进标识：<span>{{ item.followStatus | enumMessage(getHomeFollowStatusEnum) }}</span>
      </div>
      <div class="r">
        跟进时间：<span>{{ item.followDate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="line">跟进情况：{{ item.followDetails }}</div>
      <div class="divider"></div>
      <div class="pictures">
        <Uploader
          v-if="item.attachList && item.attachList.length"
          :value="item.attachList"
          :readonly="true"
        />
        <div v-else class="empty-pic">无附件</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: Object,
    default: () => {},
  },
  data() {
    return {};
  },
  mounted(){
    this.fetchEnums("getHomeCustomerConditionEnum", "getHomeFollowStatusEnum");
  }
};
</script>
<style lang="less" scoped>
.success {
  color: #07c160;
}
.danger {
  color: #ee0a24;
}
.item {
  background: white;
  margin-bottom: 10px;
  padding-bottom: 0px;
  .top {
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
  }
  .content {
    .line {
      padding: 3px 10px;
      line-height: 1.5em;
    }
  }

  .divider {
    height: 1px;
    background: @pageBg;
  }

  .pictures {
    padding: 5px 5px;
  }
  .empty-pic {
    height: 24px;
    padding: 0px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: gray;
  }
}
</style>